import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import { Routes } from "./routes";
import Corporate from "./components/Corporate/Corporate";
import Invoicing from "./components/Corporate/Invoicing";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";

const App = () => {
  // const [isTokenFound, setTokenFound] = useState(false);
  // getTokenExisting(setTokenFound);

  return (
    <Switch>
      <Route path={Routes.Invoicing.path} exact component={Invoicing} />
      <Route path={Routes.Home.path} component={Corporate} exact />
      <Route path={Routes.Terms.path} component={TermsAndConditionsPage} exact />
      <Redirect to={Routes.Home.path} />
    </Switch>
  );
};

export default App;
