import React from "react";
import { Card, Container } from "react-bootstrap";
import Header from "../components/Corporate/Header";

function TermsAndConditionsPage() {
  return (
    <div className="corporate">
      <Header />
      <Container>
        <Card className="my-4">
          <Card.Body>
            <h1>
              <strong>Términos y Condiciones de Uso de Pleelo</strong>
            </h1>
            <p>
              <strong>Última actualización: 2024-12-24</strong>
            </p>
            <p>
              Bienvenido a Pleelo. Al utilizar nuestra plataforma, aceptas
              cumplir con los siguientes términos y condiciones. Si no estás de
              acuerdo con estos términos, por favor, no utilices nuestros
              servicios.
            </p>
            <h3>1. Aceptación de los Términos</h3>
            <p>
              Al acceder y utilizar Pleelo, aceptas estar sujeto a estos
              términos y condiciones, así como a nuestra política de privacidad.
              Estos términos se aplican a todos los usuarios de la plataforma,
              incluidos los visitantes, clientes y otros que accedan o utilicen
              el servicio.
            </p>
            <h3>2. Descripción del Servicio</h3>
            <p>
              Pleelo es una plataforma de gestión empresarial que ofrece
              herramientas para la gestión de facturación, control de gastos,
              presupuestos, comunicación y nómina. Nos reservamos el derecho de
              modificar o descontinuar el servicio en cualquier momento sin
              previo aviso.
            </p>
            <h3>3. Registro y Seguridad de la Cuenta</h3>
            <p>
              Para utilizar ciertos servicios de Pleelo, es posible que debas
              registrarte y crear una cuenta. Eres responsable de mantener la
              confidencialidad de tu cuenta y contraseña, y aceptas notificar a
              Pleelo de inmediato sobre cualquier uso no autorizado de tu
              cuenta.
            </p>
            <h3>4. Uso Aceptable</h3>
            <p>
              Aceptas utilizar Pleelo únicamente para fines legales y de acuerdo
              con estos términos. No debes:
            </p>
            <ul>
              <li>
                <p>
                  Utilizar la plataforma para cualquier propósito ilegal o no
                  autorizado.
                </p>
              </li>
              <li>
                <p>
                  Interferir con el funcionamiento de la plataforma o intentar
                  acceder a áreas no autorizadas.
                </p>
              </li>
              <li>
                <p>
                  Publicar contenido que sea ofensivo, difamatorio, obsceno o
                  que infrinja los derechos de terceros.
                </p>
              </li>
            </ul>
            <h3>5. Propiedad Intelectual</h3>
            <p>
              Todos los derechos de propiedad intelectual en la plataforma y su
              contenido son propiedad de Pleelo o de sus licenciantes. No se te
              concede ningún derecho o licencia sobre la propiedad intelectual
              de Pleelo, excepto según lo expresamente establecido en estos
              términos.
            </p>
            <h3>6. Privacidad</h3>
            <p>
              Tu privacidad es importante para nosotros. Consulta nuestra
              política de privacidad para obtener información sobre cómo
              recopilamos, utilizamos y protegemos tu información personal.
            </p>
            <h3>7. Limitación de Responsabilidad</h3>
            <p>
              Pleelo no será responsable de ningún daño directo, indirecto,
              incidental, especial, consecuente o punitivo que surja del uso o
              la imposibilidad de uso de la plataforma. Esto incluye, entre
              otros, la pérdida de beneficios, datos u otras pérdidas
              intangibles.
            </p>
            <h3>8. Modificaciones a los Términos</h3>
            <p>
              Nos reservamos el derecho de modificar estos términos en cualquier
              momento. Las modificaciones entrarán en vigor inmediatamente
              después de su publicación en la plataforma. Es tu responsabilidad
              revisar periódicamente estos términos para estar al tanto de
              cualquier cambio.
            </p>
            <h3>9. Terminación</h3>
            <p>
              Podemos suspender o terminar tu acceso a Pleelo en cualquier
              momento, sin previo aviso, por cualquier motivo, incluyendo, entre
              otros, el incumplimiento de estos términos.
            </p>
            <h3>10. Ley Aplicable</h3>
            <p>
              Estos términos se regirán e interpretarán de acuerdo con las leyes
              de [País], sin tener en cuenta sus disposiciones sobre conflictos
              de leyes.
            </p>
            <h3>11. Contacto</h3>
            <p>
              Si tienes alguna pregunta sobre estos términos y condiciones, por
              favor, contáctanos en info@valegos.com.
            </p>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default TermsAndConditionsPage;
